(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name services.factory:RefereeLicence
   *
   * @description
   *
   */
  angular
    .module('neo.services')
    .factory('RefereeLicence', RefereeLicence);

  function RefereeLicence($resource, EnvironmentConfig) {
    return $resource(EnvironmentConfig.API + '/licences/:licenceId', {licenceId: '@_licenceId'},{
      update:{
        method: 'PUT'
      }
    });
  }
}());
